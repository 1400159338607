<template>
  <div>
    <div class="content bgFFF">
    <el-button type="primary" @click="$router.go(-1)" style="margin-left:950px;">返回</el-button>
      <div class="types">
        <div
          v-for="(item, index) in types"
          :key="index"
          class="item bgFFF"
          @mouseover="mouseOver(index)"
          @mouseout="mouseOut(index)"
        >
          <span>{{ index + 1 }}</span>
          <span>{{ item.name }}</span>
          <div class="icon" >
            <AuthorityComponent
              ComponentName="i"
              class="el-icon-edit"
              style="margin-right:5px;color:#0f6eff;cursor: pointer;"
              @click="editTag(item)"
              v-if="$route.meta.authority.subPage.setting.button.edit"
            />
            <AuthorityComponent
              ComponentName="i"
              v-show="curTag == index"
              class="el-icon-remove-outline"
              style="color:#F56C6C;cursor: pointer;"
              @click="deleteTag(item.categoryId)"
              v-if="$route.meta.authority.subPage.setting.button.delete"
            />
          </div>
        </div>
        <AuthorityComponent
          ComponentName="div"
          class="item bgFFF"
          @click="addTag"
          v-if="$route.meta.authority.subPage.setting.button.add"
        >
          <span />
          <span style="cursor: pointer;">创建分类标签</span>
          <i class="el-icon-circle-plus-outline" style="color:#0f6eff"/>
        </AuthorityComponent>
      </div>
    </div>
    <el-dialog :visible.sync="innerVisible" :title="title" width="30%" append-to-body>
      <el-input v-model="tagName" maxlength="10" placeholder="请输入新的分类标签名称"/>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTag">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      types: [],
      curTag: null,
      title: '创建标签',
      innerVisible: false,
      tagName: '',
      tagId: '',
      operationType: 1
    }
  },
  mounted() {
    this.getCategoryList();
    console.log("广告设置界面权限=>", this.$route.meta.authority);
  },
  methods: {
    // 广告分类列表查询
    getCategoryList() {
      const opt = {
        url: '/acb/2.0/advertCategory/list',
        method: 'get',
        data: {},
        success: res => {
          this.types = res.value
        }
      }
      this.$request(opt)
    },
    mouseOver(index) {
      this.curTag = index
    },
    mouseOut(index) {
      this.curTag = null
    },
    // 修改广告标签
    editTag(row) {
      this.title = '修改标签'
      this.innerVisible = true
      this.tagName = row.name
      this.tagId = row.categoryId
      this.operationType = 2
    },
    // 创建广告标签
    addTag() {
      this.title = '创建标签'
      this.innerVisible = true
      this.tagName = ''
      this.tagId = ''
      this.operationType = 1
    },
    // 保存广告标签
    saveTag() {
      if (this.operationType !== 3 && this.tagName === '') {
        return
      }
      // console.log("this.operationType-->", this.operationType);
      let urlN = '/acb/2.0/advertCategory/operationAdvertCategoryAdd'
      if (this.operationType == 2) {
        urlN = '/acb/2.0/advertCategory/operationAdvertCategoryUpdate'
      } else if (this.operationType == 3) {
        urlN = '/acb/2.0/advertCategory/operationAdvertCategoryDelete'
      } else {

      }


      this.innerVisible = false
      // url: '/acb/2.0/advertCategory/operationAdvertCategory',operationType 1新增 2修改 3删除
      const opt = {
        url: urlN,
        method: 'post',
        config: {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          },
        },
        data: {
          categoryId: this.tagId ? this.tagId : '',
          createUser: this.operationType === 1 ? parseInt(sessionStorage.userId) : '',
          updateUser: this.operationType !== 1 ? parseInt(sessionStorage.userId) : '',
          operationType: parseInt(this.operationType),
          name: this.tagName
        },
        success: res => {
          this.getCategoryList()
        },
        fail: err => {}
      }
      this.$request(opt)
    },
    // 删除广告标签
    deleteTag(categoryId) {
      this.$confirm('确定删除此分类?', this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.operationType = 3
          this.tagId = categoryId
          this.saveTag()
        }).catch(err => {})
    }
  }
}
</script>

<style lang="stylus" scoped>
.types
  display flex
  align-items center
  flex-direction column
  .item
    width 250px
    height 40px
    border solid 1px #ccc
    display flex
    align-items center
    justify-content space-between
    box-sizing border-box
    padding 0 10px
    .icon
      width 40px
      height 20px
      display flex
      align-items center
</style>
