var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "content bgFFF" },
        [
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "950px" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
          _c(
            "div",
            { staticClass: "types" },
            [
              _vm._l(_vm.types, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "item bgFFF",
                    on: {
                      mouseover: function ($event) {
                        return _vm.mouseOver(index)
                      },
                      mouseout: function ($event) {
                        return _vm.mouseOut(index)
                      },
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(index + 1))]),
                    _c("span", [_vm._v(_vm._s(item.name))]),
                    _c(
                      "div",
                      { staticClass: "icon" },
                      [
                        _vm.$route.meta.authority.subPage.setting.button.edit
                          ? _c("AuthorityComponent", {
                              staticClass: "el-icon-edit",
                              staticStyle: {
                                "margin-right": "5px",
                                color: "#0f6eff",
                                cursor: "pointer",
                              },
                              attrs: { ComponentName: "i" },
                              on: {
                                click: function ($event) {
                                  return _vm.editTag(item)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.$route.meta.authority.subPage.setting.button.delete
                          ? _c("AuthorityComponent", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.curTag == index,
                                  expression: "curTag == index",
                                },
                              ],
                              staticClass: "el-icon-remove-outline",
                              staticStyle: {
                                color: "#F56C6C",
                                cursor: "pointer",
                              },
                              attrs: { ComponentName: "i" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteTag(item.categoryId)
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm.$route.meta.authority.subPage.setting.button.add
                ? _c(
                    "AuthorityComponent",
                    {
                      staticClass: "item bgFFF",
                      attrs: { ComponentName: "div" },
                      on: { click: _vm.addTag },
                    },
                    [
                      _c("span"),
                      _c("span", { staticStyle: { cursor: "pointer" } }, [
                        _vm._v("创建分类标签"),
                      ]),
                      _c("i", {
                        staticClass: "el-icon-circle-plus-outline",
                        staticStyle: { color: "#0f6eff" },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.innerVisible,
            title: _vm.title,
            width: "30%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerVisible = $event
            },
          },
        },
        [
          _c("el-input", {
            attrs: { maxlength: "10", placeholder: "请输入新的分类标签名称" },
            model: {
              value: _vm.tagName,
              callback: function ($$v) {
                _vm.tagName = $$v
              },
              expression: "tagName",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveTag } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }